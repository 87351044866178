import React, { useEffect, useState } from "react"
import {Link} from 'gatsby'
import tw from "twin.macro"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = () => {
  const [offset, setOffset] = useState(false);
  
  useEffect(() => {
    window.onscroll = () => {
      if(window.pageYOffset > 100){
        setOffset(true)
      }
    }
  }, []);
  console.log(offset)

  if(window.location.pathname === "/404") return '';

return (
  <HeaderWrapper style={offset ? {background: "linear-gradient(114deg, rgba(18,18,18,1) 0%, rgba(0,0,0,1) 41%, rgba(24,24,24,1) 180%)"}: null}>
    <nav>
     <Link to="/">
        <h1 className="nav_logo">
          <span className="slash"></span>EZI
        </h1>
     </Link>
      <ul>
        <li>
          <button onClick={() => scrollTo("#about")}>About</button>
        </li>
        <li>
          <button onClick={() => scrollTo("#work")}>Work</button>
        </li>
        <li>
          <button onClick={() => scrollTo("#contact")}>Contact</button>
        </li>
      </ul>
    </nav>
  </HeaderWrapper>
)
}
export default Header

const HeaderWrapper = styled.header`
  /* ${tw`bg-transparent`} */
  position: fixed;
  width: 100%;
  background: transparent;
  z-index: 9;


  & nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: baseline;
    color: #fff;

    & ul li {
      display: inline-block;
      margin-right: 1rem;
    }

    .nav_logo {
      margin-left: 2rem;
      position: relative;

      .slash {
        height: 1rem;
        width: 2px;
        background: white;
        position: absolute;
        border-radius: 10px;
        transform: rotate(30deg);
        left: -25%;
        bottom: 40%;

        &::after {
          content: "";
          position: absolute;
          height: 1rem;
          width: 2px;
          background: white;
          bottom: -5px;
          left: 7px;
          border-radius: 10px;
          transform: rotate(180deg);
        }
      }
    }
    & button:focus {
      outline: none;
    }
  }
`
