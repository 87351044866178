import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import tw from 'twin.macro'

import Header from "./header"
import { GlobalStyle } from "../styles/GlobalStyles"

// GLobal Styles
const Wrapper = styled.div`
  footer{
    ${tw`float-right font-thin text-xs tracking-wide p-6`}
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper>
      <GlobalStyle />
      <Header />
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built by Ezzatulla
          
        </footer>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
